.iconTextCont {
  text-align: center;
  --icon-size: 6rem;

  & > *:first-child {
    margin-top: 2rem;
  }
  
  .fa-stack {
    width: var(--icon-size);
    height: var(--icon-size);
    .fa-spinner-third {
      color: rgba(0, 0, 0, 0.6);
      height: var(--icon-size);
      width: var(--icon-size);
    }
    svg:last-child {
      height: calc(var(--icon-size) * 0.4);
      width: calc((var(--icon-size) * 0.5));
    }
  }
  svg:last-of-type {
    color: var(--icon-colour);
  }
  &.green svg:last-of-type {
    color: #3baa35;
  }

  h1 {
    font-size: 1.5em;
    margin-top: 0.5em;
    margin-bottom: 0.3em;
  }

  hr + h2 {
    font-size: 1.3em;
    margin-top: 2rem;
    margin-bottom: 0.3em;
  }
  
  p {
    margin-top: 0;
    color: #000000CC;
  }
}
