.collapsibleBody {
  display: grid;
  transition: grid-template-rows 0.2s;
  grid-template-rows: 0fr;
  &[aria-hidden='false'] {
    grid-template-rows: 1fr;
  }

  & > * {
    overflow: hidden;
  }
}
