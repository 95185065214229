#container header {
  display: flex;
  flex-direction: row;
  padding: 0.6rem var(--padding-inline);
  border-bottom: 1px rgba(0, 0, 0, 0.2) solid;
  gap: 1rem;
  @media screen and (max-width: 576px) {
    gap: 0.5rem;
    padding: 0.5rem 1rem;
  }

  img {
    max-width: 45px;
    width: 15%;
  }

  div.amount {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 0.9rem;
    line-height: 1;
    gap: 2px;
  }
  
  span.amount {
    font-size: 1.5rem;
    font-family: Arial, sans-serif; // not using Poppins for numbers
  }
}
