#SkipTo {
  width: 100%;
  z-index: 9999;
  text-align: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, -105%);
  transition: transform 0.3s;
  &:focus-within {
    transform: translate(-50%, -10%);
  }

  button {
    padding: 5px;
    border-radius: 5px;
    margin: 0 2.5px;
    font-size: 1.3rem;
    font-weight: normal;
  }
}

footer,
#content,
#InfoBlock,
#PayOptions {
  outline: none;
}
