#LandingTitle {
  font-size: 1.5rem;
  margin-top: 2rem;
}

#PayOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 1.5rem 0;
  margin-inline: -1.5rem;
  column-gap: 1.5rem;

  .payOption {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    margin-bottom: 1.5rem;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 850px) {
      flex: 0.6;
      min-width: 250px;
    }
    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 0 0 1.5rem;
    }

    & > svg {
      color: var(--icon-colour);
      font-size: 2.5em;
    }
    h2 {
      font-size: 1.3em;
      margin-top: 0.5em;
      margin-bottom: 0;
    }
    p {
      margin-block: 0;
      font-size: 0.9rem;
    }
  }
  button {
    width: 100%;
    padding-inline: 10px;
    font-size: 1.2em;
    margin-top: 0.5em;
  }
}
