.errorContent {
  padding-top: 1.2rem;
  max-width: 650px;
  .custom & {
    width: 90%;
  }

  svg {
    color: #c90000;
    margin-right: 0.5rem;
  }
  h1 {
    font-size: 1.4em;
    margin-top: 0.5em;
  }

  a#ReturnToStore {
    margin-top: 1.2rem;
  }
}
