footer {
  & > *:first-child {
    background: #1B245C;
    margin: 0;
    padding: 0.7rem;
    text-align: center;
    color: white;
    font-size: 0.8rem;
  }

  & > *:last-child {
    background: #0B1737;
    padding-block: 0 0.7rem;
    padding-inline: 1rem;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr;
    column-gap: 10px;
    
    img {
      grid-area: 1 / 1 / 3 / 2;
      width: 110px;
      align-self: center;
      margin: 10px 0 0 0;
    }

    #links {
      grid-area: 1 / 2 / 2 / 3;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
      padding: 0.5rem 0;
      flex-wrap: wrap;
      column-gap: 2rem;
  
      a, button {
        text-decoration: none;
        color: white;
        padding: 0.3rem;
        font-family: inherit;
        font-weight: 500;
        font-size: 0.7rem;
        opacity: 0.8;
        background: none;
        border: none;
        cursor: pointer;
        
        &:hover, &:focus {
          opacity: 1;
        }  
      }
    }

    #disclaimer {
      grid-area: 2 / 2 / 3 / 3;
      text-align: right;
      p {
        color: white;
        opacity: 0.8;
        margin: 0;
        font-size: 0.7rem;
        font-weight: 300;
        line-height: 1rem;
      }
  
      p + p {
        margin-top: 3px;
      }
    }
  }

  @media screen and (max-width: 767.9px) {
    & > *:first-child {
      text-align: left;
      padding-inline: var(--padding-inline);
    }

    & > *:last-child {
      padding-inline: var(--padding-inline);
      img {
        grid-area: 1 / 1 / 2 / 2;
        margin-block: 15px 10px;
      }
      #links {
        grid-area: 1 / 2 / 2 / 3;
        column-gap: 1.5rem;
      }
      #disclaimer {
        grid-area: 2 / 1 / 3 / 3;
        text-align: left;
      }
    }
  }
  @media screen and (max-width: 425px) {
    & > *:last-child {
      display: flex;
      flex-direction: column;
      img {
        order: 2;
        margin: 0 0 10px 0;
        justify-self: center;
      }
      #links {
        order: 1;
        column-gap: 1.5rem;
        justify-content: space-between;
        a, button {
          flex: 1;
        }
      }
      #disclaimer {
        order: 3;
        text-align: left;
      }
    }
  }
}

footer.custom {
  & > *:last-child {
    display: flex;
    justify-content: space-between;
  }
  #disclaimer {
    text-align: right;
    padding-top: 0.7rem;
  }
}
