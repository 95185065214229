#PaymentDemoPage {
  font-family: Roboto;
  height: 100vh; // fallback
  height: 100dvh;

  h1, h2, h3 {
    color: black;
  }

  .maxWidth {
    max-width: 800px;
    margin: 0 auto;
  }

  header {
    div#InteracHeader {
      background-color: #1c1601;
      height: 50px;
      padding: 1rem;
    }
    div#BankHeader {
      height: calc(50px + 2em);
      img {
        width: 250px;
        padding-left: 0.7rem;
      }
    }
  }

  #Banner {
    background: #ffb92a;
    text-align: center;
    font-weight: 500;
    padding: 0.5em 1rem;
    font-size: 0.9em;
  }

  #InteracInfo {
    margin-left: 2rem;
    margin-bottom: 4rem;

    h1 {
      font-weight: 500;
      text-transform: capitalize;
    }
    #Amount {
      span:first-child {
        font-size: 3em;
      }
      span:last-child {
        font-size: 2em;
      }
    }
  }

  #ChooseBank {
    h2 {
      font-weight: 500;
      text-transform: capitalize;
      margin-left: 2rem;
      margin-bottom: 0.7rem;
    }
    #Banks {
      margin: 0 1rem;
      background: #d7d7d7;
      border-radius: 5px;

      div {
        padding: 1.3rem;

        button {
          border: none;
          box-sizing: border-box;
          display: inline-block;
          padding: 0;
          margin: 0;
          cursor: pointer;
        }
        img {
          width: 280px;
          display: block;
        }
      }
    }
  }

  #AcceptPayment {
    padding: 0.5rem 2rem;

    h1 {
      font-size: 1.2rem;
      font-weight: 500;
    }
    #Amount {
      font-size: 2.3rem;
      margin-bottom: 1.2rem;
    }
    button {
      font-family: Roboto;
      width: 250px;
      padding: 0.4em 3.3em;
      background: hsl(0, 100%, 32%);
      border-radius: 5px;
      color: white;
      font-size: 1.4rem;
      font-weight: normal;
      &:hover {
        background: hsl(0, 100%, 37%);
      }
    }
    #ErrorMsg {
      color: rgb(163, 0, 0);
    }
  }

  #MoneySent {
    display: flex;

    svg {
      color: green;
      margin: auto 0.5rem;
    }
    h1 {
      font-size: 1.5em;
      display: inline-block;
    }
  }
}
